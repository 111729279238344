import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import NavigationBlocker from '+containers/Dashboard/Identity/components/NavigationBlocker';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { IdentityErrorType, KAASFeeType, MerchantIDTypeAccessAPIPayloadType } from '+types';
import { capitalizeFirst, history, logError } from '+utils';

import ConfigurationConfirmationModal from '../../../components/ConfigurationConfirmationModal';
import HeroSection from '../../../components/HeroSection';
import PageBackButton from '../../../components/PageBackButton';
import IndividualSidebar from '../../../components/Sidebar/IndividualSidebar';
import UseCases from '../../../components/UseCases';
import {
  checkIfUseCasesChanged,
  convertToObject,
  convertUseCasesToObject,
  getCountry,
  transformFeesData
} from '../../../helpers/configurationHelper';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const tabs = [
  { label: 'Individual (KYC) Verification', value: 'kyc' },
  { label: 'Business (KYB) Verification', value: 'kyb' }
];
export default function Individual() {
  const id = useParams<{ id: string }>().id;
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const region = searchQuery.value.region || 'ng';
  const {
    data: defaultFees,
    isFetching: isFetchingDefaulttFees,
    refetch: refetchDefaultFees
  } = useQuery(`IDENTITY_DEFAULT_FEES`, () => api.getIdentityDefaultFees(), {});

  const {
    data: merchantFees,
    isLoading,
    refetch: refetchMerchantFees
  } = useQuery(`IDENTITY_MERCHANT_${id.toUpperCase()}`, () => api.getIdentityMerchant(id), {
    onError: (e: AxiosError) => {
      logError(e);
      feedbackInit({
        message: "There has been an error getting this merchant's information",
        type: 'danger'
      });
    }
  });

  const updateAccess = useMutation((data: MerchantIDTypeAccessAPIPayloadType) => api.grantMerchantIDTypeAccess(id, region, data), {
    onError: (error: IdentityErrorType) => {
      logError(error);
      feedbackInit({ message: error.response?.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: () => {
      feedbackInit({ message: `Fees configured successfully`, componentLevel: true, type: 'success' });
      refetchMerchantFees();
    }
  });
  const settings = merchantFees?.setting?.country?.[region];
  const defaultSettings = defaultFees?.data.setting.country[region];
  const useCasesData = transformFeesData(
    settings,
    defaultSettings,
    'default',
    convertToObject(defaultFees?.data?.identities || []),
    true,
    true
  );
  const categoryInitialState = {
    kyb: settings?.access?.kyb?.enabled,
    kyc: settings?.access?.kyc?.enabled
  } as { kyb: boolean; kyc: boolean };

  const [useCasesState, setUseCasesState] = React.useState(useCasesData);
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const activeTab = searchQuery.value.category || tabs[0].value;
  const [categoryState, setCategoryState] = React.useState(categoryInitialState);
  const updateUseCases = (useCase: KAASFeeType, section: 'kyb' | 'kyc') => {
    setUseCasesState(prev => {
      return {
        ...prev,
        [section]: prev[section].map(u => {
          if (u.key === useCase.key) {
            return useCase;
          }
          return u;
        })
      };
    });
  };
  const useCasesChanged = checkIfUseCasesChanged(useCasesState, useCasesData, activeTab, categoryState, categoryInitialState);
  const idTypeAccessStatus = {
    kyb: defaultFees?.data?.setting?.country?.[region]?.access?.kyb?.enabled,
    kyc: defaultFees?.data?.setting?.country?.[region]?.access?.kyc?.enabled
  };
  const allowedOptions = tabs.filter(tab => idTypeAccessStatus[tab.value as 'kyb' | 'kyc'] !== undefined);
  const updateCategoryState = (state: boolean) => {
    setCategoryState(prev => {
      return {
        ...prev,
        [activeTab]: state
      };
    });
  };
  const handleSave = async () => {
    const useCases = useCasesState[activeTab as keyof typeof useCasesData];
    const useCasesObject = convertUseCasesToObject(useCases, categoryState, activeTab as 'kyb' | 'kyc');
    await updateAccess.mutateAsync(useCasesObject);
  };

  useEffect(() => {
    if (merchantFees && defaultFees) {
      const settings = merchantFees?.setting.country[region];
      const defaultSettings = defaultFees.data.setting.country[region];
      const useCasesData = transformFeesData(
        settings,
        defaultSettings,
        'default',
        convertToObject(defaultFees?.data?.identities || []),
        true, // remove selfies
        true // include all use cases
      );
      const categoryInitialState = {
        kyb: settings?.access?.kyb?.enabled,
        kyc: settings?.access?.kyc?.enabled
      };

      setUseCasesState(useCasesData);
      setCategoryState(categoryInitialState);
    }
  }, [merchantFees, region, defaultFees]);

  const getActiveTabName = () => tabs.find(tab => tab.value === activeTab)?.label || '';

  return (
    <div className="identity-configuration">
      <NavigationBlocker blockNavigation={useCasesChanged} />
      <div className="content-i">
        <div className="content-box">
          {isLoading ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingPlaceholder type="text" content={1} section={4} />
              </div>
            </div>
          ) : (
            <>
              <PageBackButton onClick={() => history.push(`/dashboard/identity/merchants/${id}`)} label={`Back to Configuration`} />

              <HeroSection
                title={`Configure Identity Use Case for ${capitalizeFirst(merchantFees?.name || '')}`}
                description={`Here, you can configure how ${capitalizeFirst(
                  merchantFees?.name || ''
                )} verifies individual customers and businesses to ensure secure and compliant identity verification processes.`}
                button={{
                  name: `Save Changes`,
                  disabled: !useCasesChanged || updateAccess.isLoading,
                  action: () => {
                    setOpenSaveModal(true);
                  },
                  color: 'success',
                  icon: <i className="os-icon os-icon-save" />
                }}
              />
              <div className="d-flex gap-2 identity-configuration-content">
                <IndividualSidebar region={region} id={id} />
                <div className="flex-grow-1">
                  <div className="toggle-buttons">
                    <TabSwitch
                      options={tabs}
                      activeTab={getActiveTabName()}
                      setTab={tab => searchQuery.setQuery({ category: String((tab as { value: string }).value) })}
                      identifier="label"
                      allowedOptions={allowedOptions}
                    />
                  </div>
                  <h3>{`Preferred ${activeTab?.toUpperCase()} Use Cases for ${getCountry(region)}`}</h3>
                  <UseCases
                    updateUseCases={updateUseCases}
                    useCaseOptions={useCasesState[activeTab as keyof typeof useCasesData]}
                    category={activeTab as 'kyc' | 'kyb'}
                    updateSectionState={updateCategoryState}
                    sectionState={categoryState[activeTab as keyof typeof categoryState]}
                    region={region}
                    type="merchant"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ConfigurationConfirmationModal openSaveModal={openSaveModal} handleClick={handleSave} close={() => setOpenSaveModal(false)} />
    </div>
  );
}
